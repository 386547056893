@import "/src/var";
.custom-select {
  &__control {
    // background-color: $main-color !important;
    background: var(--color-background) !important;
    border-radius: 10px !important;
  }

  &__value-container {
    padding: 0 !important;
    color: var(--color-text) !important;
    text-align: center !important;
  }
  &__placeholder {
    color: var(--color-text) !important;
  }
  &__single-value {
    color: var(--color-text) !important;
  }
  &__menu {
    // background-color: $main-color !important;
    background: var(--main-color) !important;
    color: var(--color-text);
    border-radius: 15px !important;
    margin-bottom: 0 !important;
  }
  &__menu-list {
    margin-bottom: 0 !important;
  }
  &__option {
    cursor: pointer !important;
    border-radius: 15px !important;
    &--is-focused {
      //   background-color: lighten($main-color, 20%) !important;
      background-color: var(--color-background) !important;
      // color: var(--color-background) !important;
      border-radius: 10px !important;
    }
    &--is-selected {
      border-radius: 10px !important;
      //background-color: lighten($main-color, 20%) !important;
      color: var(--color-text) !important;
      background-color: var(--color-background) !important;
    }
  }
}
