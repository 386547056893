@import "/src/var";

.header {
  max-width: 1440px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  z-index: 11;
  // padding-top: env(safe-area-inset-top);
}
.header__wrapper {
  background: var(--main-color-light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // color: var(--color-text);

  height: 100%;
  margin: 0 5px;
  border-radius: 10px;
  // background-color: $main-color;
  background: var(--main-color);
  .brand_name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 16px;
    // color: $white-color;
    color: var(--color-text);
    text-decoration: none;
    margin-left: $margin-left-header;
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }
  .right_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: $margin-left-header;
    &:hover {
      li {
        .active_link {
          ::after {
            width: 0;
          }
        }
      }
    }

    li {
      list-style-type: none;
      // margin-left: 30px; // поправить!!!!!
      margin-left: 10px;
      // color: var(--color-text);
      text-decoration: none;
      transition: $transition;
      a {
        // color: var(--color-text);

        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        padding: 10px 5px;
        font-size: 14px;
        position: relative;
        @media (max-width: 550px) {
          font-size: 10px;
          padding: 5px 0;
        }
        @media (max-width: 450px) {
          font-size: 9px;
        }
        ::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          background: none repeat scroll 0 0 transparent;
          height: 2px;
          width: 0;
          //  background: #fff;
          background: var(--color-text);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }
        :hover::after {
          width: 100%;
          left: 0;
          .active-link {
            //   background-color: aqua;
          }
        }
      }
    }
    .link {
      color: var(--color-text);
      text-decoration: none;
    }
    .active_link {
      color: rgb(131, 47, 47);
      color: $white;
      // color: lighten($main-color, 50%);
      text-decoration: none;
      transition: $transition;

      ::after {
        width: 100%;
        left: 0;
      }
    }
  }
}
