@import '/src/var';

.example {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: $button-width;

  height: 320px;
  //background-color: var(--color-background);
  border-radius: 10px;
  border: none;

  &:hover {
    .subtitle {
      height: 80px;
      p {
        opacity: 1;
      }
      img {
        opacity: 1;
      }
    }
  }
  @media (max-width: 470px) {
    width: 90vw;
  }
}
.example_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
  position: relative;
  z-index: 8;
  @media (max-width: 450px) {
    height: 90%;
  }
}
.subtitle {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10%;
  background-color: var(--color-background);
  border-radius: 10px;

  transition: all 0.5s;

  h4 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    color: var(--color-text);
    @media (max-width: 390px) {
      font-size: 16px;
    }
  }
  p {
    margin-left: 10px;
    color: var(--color-text);
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  img {
    margin-left: 10px;
    width: 7%;
    opacity: 0;
    transition: opacity 0.8s;
  }
}

.commercial {
  position: absolute;
  top: 5px;
  left: 5px;
  background: linear-gradient(
    to right,
    rgb(81, 71, 223),
    rgb(230, 79, 233),
    rgb(221, 70, 70)
  );
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  font-weight: 500;
}
.testTask {
  position: absolute;
  top: 5px;
  left: 5px;

  background: rgb(177, 42, 49);
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  font-weight: 500;
}
