@import "/src/var";

.footer {
  height: 80px;
  margin-top: 10px;
  max-width: 1440px;
  width: 100%;
  margin-bottom: 0;
  align-self: end;
}
.footer_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  // background-color: $main-color;
  background: var(--main-color);
  border-radius: 10px;
  margin: 0 5px;
}
.block_1 {
  margin-left: $margin-left-header;

  h1 {
    color: var(--color-text);
    @media (max-width: 500px) {
      font-size: 20px;
      width: min-content;
    }
  }
}
.block_2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 40px;
    filter: invert(100%);
    @media (max-width: 500px) {
      width: 30px;
    }
  }
}
.block_3 {
  margin-right: $margin-left-header;
  h3 {
    color: var(--color-text);
    font-size: 24px;
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
}
