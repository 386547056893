//@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import "/src/var";

html {
  background: var(--color-background);
  height: 100dvh;
  transition: all 1s;
}
@media (max-width: 428px) {
  /* Цвет статус бара */
  :root {
    --status-bar-color: #d9d911;
  }
}

@media (min-width: 429px) {
  /* Цвет статус бара */
  :root {
    --status-bar-color: #dc0e0e;
  }
}
body {
  margin: 0 auto;
  //font-family: "DM Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  max-width: 1440px;
  width: 100%;
  color: var(--color-text);
  //background: var(--color-background);
  height: 100vh;
  overflow-x: hidden;
  cursor: default;
  transition: all 1s;
  // @media not all and (hover: none);
}

body::-webkit-scrollbar {
  width: 0;
}
// .dark {
//   background: $background-color-dark;
//   // $main-color: blue;
// }
