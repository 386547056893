@import "/src/var";

.wrapper {
  max-width: 1440px;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 80px;
}
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1430px;
  width: 100%;
  // background-color: $main-color;
  background: var(--main-color);
  border-radius: 10px;
  color: var(--color-text);
  height: fit-content;
  margin: 0 auto;
}
.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
  h1 {
    width: 90%;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 16px;
    color: var(--color-text);
    margin-left: $margin-left-header;
    @media (max-width: 450px) {
      font-size: 20px;
    }
  }
}
.select_bar {
  width: 20%;
  border-radius: 0px;
  z-index: 10;
  margin-right: 30px;
  @media (max-width: 500px) {
    width: 40%;
  }
}

.title_bar {
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: 19px;
  font-weight: 300;
  margin: 0 1%;
  width: 50%;
  // li {
  //   list-style-type: none;
  //   margin: 0 2%;
  //   border-radius: 10px;
  //   padding: 0 10px;
  //   border: red 1px solid;
  //   cursor: pointer;
  //   transition: $transition;
  //   &:hover {
  //     border: $white-color 1px solid;
  //   }
  // }
  // .active {
  //   background-color: $background-color-dark;
  // }
}
// .dropdown {
//   position: relative;
//   display: inline-block;
//   right: 15px;
//   transition: $transition;

// button {
//   width: fit-content;
//   height: 40px;
//   background-color: #6c6c6d;
//   color: white;
//   padding: 16px;
//   font-size: 16px;
//   border: none;
//   @media (max-width: 450px) {
//     height: 30px;
//     padding: 10px 20px;
//     font-size: 12px;
//   }
// }

// .dropdown_content {
//   display: none;
//   position: absolute;
//   background-color: #f1f1f1;
//   min-width: 160px;
//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   transition: $transition;
//   border-radius: 10px;
//   right: 0px;
//   z-index: 15;
//   border: white 1px solid;
//   @media (max-width: 450px) {
//     flex-direction: row;
//     top: 0;
//     right: 42px;
//     height: 28px;
//   }
// }

// .dropdown_content li {
//   color: black;
//   padding: 12px 16px;
//   text-decoration: none;
//   display: block;
//   border-radius: 10px;
//   @media (max-width: 450px) {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 10px 10px;
//     font-size: 12px;
//   }
// }

// .dropdown_content li:hover {
//   background-color: #ddd;
//   cursor: pointer;
// }

// .dropdown:hover .dropdown_content {
//   display: block;
//   @media (max-width: 450px) {
//     display: flex;
//   }
// }

//   .dropdown:hover .dropbtn {
//     background-color: #1dde23;
//   }
//   &:hover {
//     .dropdown_content {
//       display: block;
//       @media (max-width: 450px) {
//         display: flex;
//       }
//     }
//     button {
//       background-color: #1dde23;
//     }
//   }
// }

// .test {
//   .test_bar {
//     background-color: #585656;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     width: fit-content;
//     border-radius: 10px;
//     li {
//       list-style-type: none;
//       padding: 10px 10px;
//     }
//     .active_test {
//       background-color: #1dde23;
//       border-radius: 10px;
//     }
//     .no_active_test {
//       opacity: 0.3;
//       // display: none;
//     }
//     .visible {
//       display: block;
//     }
//   }
// }
.example_works {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($button-width, 1fr));
  // grid-template-columns: repeat(auto-fill, minmax(90vw, 1fr));
  justify-content: center;
  justify-items: center;
  grid-column-gap: 18px;
  grid-row-gap: 20px;
  margin-top: $main-margin-top;
  margin-bottom: $main-margin-top;
  width: 95%;
  height: fit-content;
  @media (max-width: 470px) {
    grid-template-columns: repeat(auto-fill, minmax(90vw, 1fr));
  }
}
.bottom_button {
  width: 95%;
  margin-bottom: $main-margin-top;
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    height: $button-height;
    width: $button-width;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border-radius: 10px;
    border: none;
  }
}
