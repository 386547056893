@import "/src/var";

.wrapper {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 80px;
}
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1430px;
  width: 100%;
  // background-color: $main-color;
  background: var(--main-color);
  border-radius: 10px;
  //color: red;
  height: 100%;
  margin: 0 auto;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 16px;
  color: var(--color-text);
  align-self: flex-start;

  margin-left: $margin-left-header;
  @media (max-width: 450px) {
    font-size: 20px;
    line-height: 30px;
    width: 90%;
  }
}
button {
  height: $button-height;
  width: $button-width;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-radius: 10px;
  border: var(--color-text) 1px solid;
  background-color: var(--main-color);
  color: var(--color-text);
  transition: $transition;
  align-self: center;
  cursor: pointer;
  &:hover {
    color: var(--color-text);
    background-color: var(--color-background);
    // border: $blue 1px solid;
    border: var(--color-background) 1px solid;
  }

  img {
    width: 30px;
    margin-left: 10px;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
  .github_logo {
    filter: invert(100%);
  }
}
