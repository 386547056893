@import "/src/var";

.welcome_screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background-color: $main-color;
  background: var(--main-color);

  height: 100%;
  border-radius: 10px;
  margin-top: 0;
  transition: $transition;
  color: var(--color-text);
}

// .dark {
//   background-color: blue;
// }
.portfolio_information {
  width: 80%;
  height: 90%;
  //margin-top: $main-margin-top;
  //background-color: rgb(145, 146, 146);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio_top {
  //background-color: cadetblue;
  width: $width-main;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background-color: aqua;
  .portfolio_img {
    width: 220px;
    height: 220px;

    margin-right: 30px;
    img {
      width: 220px;
      border-radius: 50%;
      box-shadow: 0px 0px 20px 1px #f59167;
      @media (max-width: 460px) {
        width: 150px;
      }
    }
  }
  .portfolio_block {
    // background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 46px;
      // color: $white-color;
      @media (max-width: 460px) {
        font-size: 36px;
      }
    }
    h3 {
      font-size: 26px;
      //  color: $white-color;
    }

    // .portfolio_links {
    //   display: flex;
    //   flex-direction: row;
    //   width: 100%;
    //   border: black 1px solid;
    //   justify-content: space-around;
    //   li {
    //     list-style-type: none;
    //     img {
    //       width: 40px;
    //     }
    //   }
    // }
  }
}

.portfolio_bottom {
  width: $width-main;
  margin: 0 auto;
  margin-top: $main-margin-top;
  // border: black 1px solid;
  height: fit-content;
  // border: white 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: $main-margin-top;

  width: 60%;
  //border: white 1px solid;
  @media (max-width: 745px) {
    width: 100%;
  }

  a {
    width: 60%;
    border: var(--color-text) 1px solid;
    border-radius: 10px;
    height: 50px;
    text-decoration: none;
    // color: $white-color;
    color: var(--color-text);
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: $transition;
    padding: 0 auto;
    margin: 15px auto;
    img {
      // border: black 1px solid;
      width: 28px;
      height: 20px;

      margin-left: 6px;
    }
    &:hover {
      background-color: var(--color-background);
      border: var(--color-background) 1px solid;
      // color: var(--color-background);
    }
    @media (max-width: 745px) {
      width: 100%;
    }
  }
  // .buttons {
  //   width: 100%;
  //   height: fit-content;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   margin-bottom: $main-margin-top;
}
