@import "/src/var";

.main {
  max-width: 1440px;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  margin-top: 80px;
  @media (max-width: 1050px) {
    height: fit-content;
  }
}
.main_wrapper {
  margin: 0 5px;
  border-radius: 10px;
  height: 100%;
  background-color: rgb(196, 246, 246);
  // background-color: $main-color;
  background: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1050px) {
    // height: fit-content;
  }

  h1 {
    text-align: center;
    color: var(--color-text);
  }
}
.project_preview {
  -webkit-box-shadow: 0px 0px 30px 12px rgba(227, 230, 232, 0.05);
  -moz-box-shadow: 0px 0px 30px 12px rgba(227, 230, 232, 0.05);
  box-shadow: 0px 0px 30px 24px rgba(227, 230, 232, 0.05);

  width: 60%;
  border-radius: 15px;
  @media (max-width: 1050px) {
    width: 50vw;
    height: 30vw;
  }
  @media (max-width: 450px) {
    width: 90vw;
    height: 60vw;
    height: fit-content;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
}
.project_skills {
  margin-top: $main-margin-top;
  width: 60%;
  color: var(--color-text);
  @media (max-width: 1050px) {
    width: 90%;
  }

  h3 {
    display: inline;
    margin-left: 10px;
  }
  p {
    line-height: 28px;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: center;
  margin-top: $main-margin-top;
  margin-bottom: $main-margin-top;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}
.gitHubLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: $white 1px solid;
  width: 30%;
  height: 50px;
  text-align: center;
  border-radius: 8px;
  margin-right: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (max-width: 700px) {
    width: 25vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: $main-margin-top;
    margin-right: 0;
  }
  img {
    width: 15%;
    margin-right: 30px;
    filter: invert(100%);
    @media (max-width: 700px) {
      width: 3vw;
      margin-right: 2vw;
    }
  }
  h3 {
    color: $white;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
  &:hover {
    background-color: var(--color-background);

    border: var(--color-background) 1px solid;
  }
}
.siteLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: $white 1px solid;
  width: 30%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (max-width: 700px) {
    width: 25vw;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  img {
    width: 50%;
    filter: invert(100%);
  }
  &:hover {
    background-color: var(--color-background);
    border: var(--color-background) 1px solid;
  }
}
