@import "/src/var";

.switch {
  display: inline-block;
  position: relative;
  height: 30px;
  height: fit-content;
  margin-top: 8%;
}

.switch__input {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.switch__label {
  position: relative;
  display: inline-block;
  width: 50px;

  height: 26px;
  height: 14px;
  background-color: #2b2b2b;
  border: 2px solid #5b5b5b;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.switch__indicator {
  position: absolute;
  top: 53%;
  left: 59.5%;
  transform: translate(-90%, -50%) translateX(-30%);
  display: block;
  width: 24px;
  height: 24px;
  height: 13px;
  background-color: #7b7b7b;
  border-radius: 9999px;
  box-shadow: 10px 0px 0 0 rgba(#000000, 0.2) inset;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    background-color: #ffffff;
    border-radius: 9999px;
  }

  // &::before {
  //   top: 7px;
  //   left: 7px;
  //   width: 5px;
  //   height: 5px;

  //   background-color: #ffffff;
  //   opacity: 0.6;
  // }

  // &::after {
  //   bottom: 8px;
  //   right: 6px;
  //   width: 9px;
  //   height: 9px;
  //   background-color: #ffffff;
  //   opacity: 0.8;
  // }
}

.switch__decoration {
  position: absolute;
  top: 85%;
  left: 57%;
  display: block;
  width: 2px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  // animation: twinkle 0.8s infinite -0.6s;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    height: 2px;

    background-color: #ffffff;
    border-radius: 9999px;
  }

  &::before {
    top: -10px;
    left: 5px;
    opacity: 1;
    // animation: twinkle 0.6s infinite;
  }

  &::after {
    top: -3px;
    left: 13px;
    // animation: twinkle 0.6s infinite -0.2s;
  }
}

@keyframes twinkle {
  50% {
    opacity: 0.2;
  }
}

.switch__indicator {
  &,
  &::before,
  &::after {
    transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }
}

.switch__input:checked + .switch__label {
  background-color: #8fb5f5;
  border-color: #347cf8;

  .switch__indicator {
    background-color: #ecd21f;
    box-shadow: none;
    transform: translate(-50%, -50%) translateX(30%);
    &::before,
    &::after {
      display: none;
    }
  }

  .switch__decoration {
    top: 65%;
    transform: translate(0%, -50%);
    animation: cloud 8s linear infinite;
    width: 10px;
    // width: 20px;
    left: 35%;
    height: 10px;

    &::before {
      width: 5px;
      height: 5px;
      top: auto;
      bottom: 0;
      left: -4px;
      animation: none;
    }

    &::after {
      width: 7px;
      height: 7px;
      top: auto;
      bottom: 0;
      left: 8px;
      animation: none;
    }

    &,
    &::before,
    &::after {
      border-radius: 9999px 9999px 0 0;
    }

    &::after {
      border-bottom-right-radius: 9999px;
    }
  }
}

@keyframes cloud {
  0% {
    transform: translate(0%, -50%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(0%, -50%);
  }
}
